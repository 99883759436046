'use client';

import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabCartContext } from '@/lab-test/context/lab-cart-context';

import { Fragment } from 'react';
import type { MouseEvent } from 'react';

import styles from './index.module.scss';

export default function PatientList() {
    const { testId } = useLabTestContext();
    const { addToCart, removeFromCart, testsInCart } = useLabCartContext();

    function handleClick(event: MouseEvent<any>, patientCount: number) {
        event.stopPropagation();
        addToCart(patientCount);
    }

    function removeCartItem(event: MouseEvent<any>) {
        event.preventDefault();
        event.stopPropagation();
        removeFromCart(testId);
    }

    return (
        <div className={styles['patients-list']}>
            {testsInCart[testId] && (
                <div className={styles['remove-test']} onClick={(event: MouseEvent<any>) => removeCartItem(event)}>
                    Remove Test
                </div>
            )}
            {patientList?.map((item) => (
                <div
                    key={item.value}
                    onClick={(event: MouseEvent<any>) => handleClick(event, item.value)}
                    className={testsInCart[testId]?.patientCount === item.value ? styles['active'] : ''}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );
}

export const patientList = [
    {
        label: '1 Patient',
        value: 1
    },
    {
        label: '2 Patients',
        value: 2
    },
    {
        label: '3 Patients',
        value: 3
    },
    {
        label: '4 Patients',
        value: 4
    },
    {
        label: '5 Patients',
        value: 5
    }
];
