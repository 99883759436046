import useBseCart from '@/services/hooks/use-bse-cart';
import useDefaultLocation from '@/services/hooks/use-default-location';
import { R_MANAGE_ADDRESS, R_SHOPPING_CART } from '@/services/utils/path';
import { v1_arrow_back, v1_cancel, v1_check, v1_close } from '@/services/utils/svg';
import Link from 'next/link';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';

interface CustomToastMessageCart {
    type?: 'add' | 'remove';
    asLabel?: boolean;
    label?: string;
    close?: boolean;
    noLink?: boolean;
    check?: boolean;
    linkLabel?: string;
    link?: string;
}

export const CustomToastMessageCart = ({
    type = 'add',
    label,
    asLabel,
    close = true,
    check,
    linkLabel,
    link,
    noLink = false
}: CustomToastMessageCart) => {
    const { cartBseData } = useBseCart();
    const { locationData } = useDefaultLocation();

    useEffect(() => {
        const dismissToast = setTimeout(() => {
            toast.dismiss();
        }, 2000);

        return () => clearTimeout(dismissToast);
    }, []);

    const isCart = cartBseData?.uc_address?.ul_address || locationData?.ul_address;

    // href={link ? link : isCart ? R_SHOPPING_CART : `${R_MANAGE_ADDRESS}?goto=${R_SHOPPING_CART}`}
    return (
        <div className="toast_with_link_cart">
            {close && <button onClick={() => toast.dismiss()}>{v1_cancel}</button>}
            {check && (
                <button className={`iconSuccess`} onClick={() => toast.dismiss()}>
                    {v1_check}
                </button>
            )}
            <span>
                {!asLabel && (type === 'add' ? 'Added to' : 'Removed From')} {label ? label : 'your cart'}
            </span>
            {!noLink && (
                <Link href={link ? link : isCart ? R_SHOPPING_CART : R_SHOPPING_CART}>
                    {linkLabel ? linkLabel : 'View Cart'} {v1_arrow_back}
                </Link>
            )}
        </div>
    );
};
