import querystring from 'qs';
import parser from 'ua-parser-js';
import { API_BASE_URL, IS_PRODUCTION, LT_BASE_URL, userStorageName } from './config';

interface ParsedAgent {
    browser?: {
        name?: string;
        version?: string;
    };
    os?: {
        name?: string;
        version?: string;
    };
}

const agentChecker = (userAgentVar: string): ParsedAgent => {
    const { browser, os } = parser(userAgentVar);
    return { os, browser };
};

const serviceChecker = (endpoint: string) => {
    const service: string = endpoint.split('/')[0];

    if (service === 'lab-order' || service === 'misc' || service === 'patient' || service === 'translator' || service === 'lab-search') {
        return LT_BASE_URL;
    } else {
        return API_BASE_URL;
    }
};

const getUrl = (endpoint: string, userAgentVar?: string, includeGeneral: boolean = true): string => {
    const { os, browser } = agentChecker(userAgentVar || '');
    const extra = querystring.stringify({
        f: ['iPhone', 'iPod', 'iPad', 'Android', 'BlackBerry', 'Mobile'].includes(os?.name || '') ? 'mweb' : 'web',
        b: browser?.name || '',
        v: browser?.version || '',
        os: os?.name || '',
        osv: os?.version || ''
    });
    if (includeGeneral) {
        return `${serviceChecker(endpoint)}/general/${endpoint}${endpoint.includes('?') ? '&' : '?'}${extra}`;
    }
    return `${serviceChecker(endpoint)}/${endpoint}${endpoint.includes('?') ? '&' : '?'}${extra}`;
};

const handleResponse = async (endpoint: string, method: string, body: any, headers: any, user: any): Promise<any> => {
    if (user?.authToken) {
        try {
            const response = await fetch(`${serviceChecker(endpoint)}/auth/v1/token/refresh/`, {
                method: 'POST',
                credentials: 'include'
                // next: { revalidate: 60 * 10 },
                // headers: {
                //     'Content-Type': 'application/x-www-form-urlencoded',
                //     Authorization: `Bearer ${user.authToken}`,
                //     ...headers
                // }
            });

            const res = await response.json();
            console.log(res, 'TODO: Refresh Token');

            if (res.status === 'success') {
                const newAuthToken = res?.data?.authToken;
                const updatedUser = { ...user, authToken: newAuthToken };
                localStorage.setItem(userStorageName, JSON.stringify(updatedUser));

                // if (typeof window !== 'undefined') {
                //     window.location.reload();
                // }

                return await request({
                    endpoint,
                    method,
                    body,
                    headers,
                    user
                });
            }
            throw new Error('API TOKEN REFRESH ERROR');
        } catch (error) {
            throw error;
        }
    }

    // throw new Error('Handle response error');
    // console.log('object');
};

export const isValidJSON = (data: string | null) => {
    if (data) {
        try {
            JSON.parse(data);
            return true;
        } catch (error) {
            return false;
        }
    }
};

export const request = async ({ endpoint, includeGeneral, method = 'GET', body, headers = {}, source = null }: any): Promise<any> => {
    const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
    const userTest = getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
    const accessToken = userTest?.authToken || null;
    const authHeaders: Record<string, string> = accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    const url = getUrl(endpoint, '', includeGeneral);

    try {
        const requestBody = body
            ? typeof window !== 'undefined' && body instanceof FormData
                ? body
                : new URLSearchParams(body)
            : undefined;

        const fetchOptions: RequestInit = {
            method,
            headers: {
                ...(body ? {} : {}),
                ...headers,
                ...authHeaders
            },
            next: { revalidate: process.env.REVALIDATE_TIME && IS_PRODUCTION === 'false' ? +process.env.REVALIDATE_TIME : 60 * 10 },
            // next: { revalidate: 60 * 10 },
            // credentials: 'include',
            signal: source?.token
        };

        if (body && method !== 'GET') {
            fetchOptions.body = requestBody;
        }

        const response = await fetch(url, fetchOptions);

        if (!response.ok) {
            if (response.status === 401 || response.statusText === 'Forbidden') {
                return handleResponse(endpoint, method, body, headers, userTest);
            }
            // alert(JSON.stringify(response.status));
            console.log('CHECKING RESPONSE', response);
            console.log('Url ----->', url);
            // console.log('Response ----->', response);
            return await response.json();
            throw new Error(`Request failed with status ${response?.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('Error url --->>>', url);
        // throw error;
    }
};

export const getSpecialOffers = (): Promise<any> => request({ endpoint: 'v1/offers', includeGeneral: false, method: 'GET' });

// export const requestStock = (m_id: any) => {
//     return request({
//         endpoint: 'v1/requestStock/',
//         method: 'POST',
//         body: {
//             m_id
//         }
//     });
// };

export const userProfileUpdate = (info: any) => {
    // const data: any = {
    //     u_name: info?.u_name,
    //     u_mobile: info?.u_mobile,
    //     u_email: info?.u_email,
    //     u_profile_pic: info?.u_profile_pic
    // };
    // const formData = new FormData();
    // Object.keys(data).forEach((key) => {
    //     const value = data[key];
    //     if (!value) {
    //         return;
    //     }
    //     formData.append(key, value);
    // });
    return request({
        endpoint: 'v1/profile',
        method: 'POST',
        includeGeneral: false,
        body: info
    });
};

//user address
export const allLocations = () => {
    return request({
        endpoint: `v1/allLocations/`,
        includeGeneral: false,
        method: 'GET'
    });
};

export const getUserAddresses = (obj: any) => {
    return request({
        endpoint: `userLocation/v1/?${querystring.stringify(obj)}`,
        includeGeneral: false,
        method: 'GET'
    });
};
export const setCartDefaultAddress = (locationId: any) => {
    return request({
        endpoint: `cart/v1/address`,
        method: 'POST',
        body: {
            ul_id: locationId
        }
    });
};

export const setNewAddress = (addressObj: any) => {
    return request({
        endpoint: `userLocation/v1/`,
        includeGeneral: false,
        method: 'POST',
        body: addressObj
    });
};

export function updateAddress(userLocationId: any, addressObj: any) {
    return request({
        endpoint: `userLocation/v1/${userLocationId}`,
        includeGeneral: false,
        method: 'POST',
        body: addressObj
    });
}

export const deleteAddress = (userLocationId: number) => {
    return request({
        endpoint: `userLocation/v1/${userLocationId}`,
        includeGeneral: false,
        method: 'DELETE'
    });
};

//Register Pharmacy
export const registerPharmacy = (data: any) => {
    return request({
        endpoint: 'v2/pharmacy',
        method: 'POST',
        body: data
    });
};

// checkout time slots
export const getAllTimeSlots = () => {
    return request({
        endpoint: `v1/timeSlots`,
        includeGeneral: false,
        method: 'GET'
    });
};

export const getPharmacyStatus = () => {
    return request({
        endpoint: 'v1/getPharmacyStatus',
        method: 'GET'
    });
};

// page
export const getFaqHeaders = () => {
    return request({
        endpoint: 'v1/faqsHeaders',
        includeGeneral: false
    });
};

export const getSingleFaqs = (slug: string) => {
    return request({
        endpoint: `v1/faqs/${slug}`,
        includeGeneral: false
    });
};

export const additionalPageContent = (slug: string) => {
    return request({
        endpoint: `v1/page/${slug}`,
        includeGeneral: false
    });
};
