'use client';

import { useRouter } from 'next/navigation';
import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { lt_not_available_item, lt_not_available_location } from '@/lab-test/svg';

import { ErrorTag, Open } from '@/services/types/lab-test';
import { Science } from '@/lab-test/svg-icon-components';

import Title from '@/lab-test/atoms/title';

import styles from './index.module.scss';

export default function NaModal({ errorTag, message }: { errorTag: string; message?: string }) {
    const router = useRouter();
    const { setOpen } = useLabTestContext();

    function handleClikc() {
        setOpen((prev) => (prev = Open.NONE));
        router.push('/shopping-cart/lab-test/');
    }

    return (
        <div className={styles['modal-wrapper']}>
            <div className={styles['modal-content']}>
                <div className={styles['icon']}>
                    {errorTag === ErrorTag.ITEM_UNAVAILABLE ? (
                        lt_not_available_item
                    ) : errorTag === ErrorTag.SERVICE_UNAVAILABLE ? (
                        lt_not_available_location
                    ) : errorTag === ErrorTag.ITEM_MAX_REACHED ? (
                        <Science />
                    ) : null}
                </div>
                <Title content="Sorry" />
                <p>{message || 'Unfortunately we are not available at your location.'}</p>
                <button onClick={handleClikc}>Done</button>
            </div>
        </div>
    );
}
