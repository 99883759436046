import { request } from '../arogga-api';

export const getTestsCart = () => {
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: 'lab-order/api/v1/carts/my'
    });
};

export const applyLabCoupon = (coupon: string) => {
    return request({
        method: 'PUT',
        includeGeneral: false,
        endpoint: 'lab-order/api/v1/carts/my/coupon',
        body: { name: coupon }
    });
};

export const addTestToCart = (labItemUqid: string, patientCount: number) => {
    return request({
        method: 'POST',
        includeGeneral: false,
        endpoint: 'lab-order/api/v1/carts/my/items',
        body: {
            labItemUqid,
            patientCount
        }
    });
};

export const deleteTestCartItem = (cartItemUqid: string) => {
    return request({
        includeGeneral: false,
        endpoint: `lab-order/api/v1/carts/my/lab-items/${cartItemUqid}`,
        method: 'DELETE'
    });
};

export const updateTestCartItem = (cartItemUqid: string, patientCount: number) => {
    return request({
        endpoint: `lab-order/api/v1/carts/my/lab-items/${cartItemUqid}`,
        includeGeneral: false,
        method: 'PUT',
        body: {
            patientCount
        }
    });
};

export const updateCartAddress = async (userLocationId: number, locationId: number) => {
    return request({
        endpoint: `lab-order/api/v1/carts/my/location`,
        includeGeneral: false,
        method: 'PUT',
        body: {
            userLocationId,
            locationId
        }
    });
};

export const getCheckoutData = () => {
    return request({
        endpoint: `lab-order/api/v1/carts/my/checkout`,
        includeGeneral: false,
        method: 'GET'
    });
};

export const placeOrder = (body: any) => {
    return request({
        body,
        method: 'POST',
        includeGeneral: false,
        endpoint: `lab-order/api/v1/orders`
    });
};

export const getTimeSchedule = () => {
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-order/api/v1/shared/schedule-dates`
    });
};

export const toggleHardCopy = (body: any) => {
    return request({
        body,
        method: 'PUT',
        includeGeneral: false,
        endpoint: `lab-order/api/v1/carts/my/hard-copy`
    });
};
