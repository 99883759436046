import { Test } from './tests';
import { Dispatch, SetStateAction } from 'react';

export interface Name {
    en: string;
    bn: string;
}

export interface Location {
    id: string;
    division: string;
    district: string;
    area: string;
    lat: number;
    long: number;
    hubId: string;
    zone: string;
}

export interface UserLocation {
    id: string;
    userId: string;
    locationId: string;
    subareaId: string;
    name: string;
    mobileNumber: string;
    type: string;
    address: string;
    isDefault: boolean;
    lat: number;
    long: number;
    location: string;
    isChecked: boolean;
    division: string;
    district: string;
    area: string;
    hubId: string;
    zone: string;
    subareaTitle: string;
    subareaZone: string;
}

export interface Response {
    success: boolean;
    message: string;
    count: number;
}

export enum SectionTag {
    LIFE_STYLE = 'life_style',
    CHECKUP_MEN = 'checkup_men',
    VITAL_ORGANS = 'vital_organs',
    CHECKUP_WOMEN = 'checkup_women',
    HEALTH_CONCERN = 'health_concern',
    HEALTH_PACKAGE = 'health_package'
}

export enum Status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DELETED = 'deleted',
    ARCHIVED = 'archived'
}

export interface FilterStatus {
    label: string;
    value: string;
}

export enum ActionType {
    ADD_TO_CART = 'add_to_cart',
    REMOVE_FROM_CART = 'remove_from_cart'
}

export interface Action {
    type: ActionType;
    payload?: any;
}

export enum ErrorTag {
    ITEM_UNAVAILABLE = 'item_unavailable',
    LOCATION_NOT_SET = 'location_not_set',
    ITEM_MAX_REACHED = 'item_max_reached',
    SERVICE_UNAVAILABLE = 'service_unavailable'
}

export enum Open {
    NONE = '',
    LOGIN = 'login_modal',
    GENDER = 'gender_modal',
    SORT_BY = 'sort_by_modal',
    RELATION = 'relation_modal',
    FILTER_BY = 'filter_by_modal',
    BOOK_TEST = 'book_test_modal',
    MORE_TEST = 'more_tests_modal',
    LOCATION_NOT_SET = 'location_not_set',
    ITEM_UNAVAILABLE = 'item_unavailable_modal',
    ITEM_MAX_REACHED = 'item_max_reached_modal',
    SERVICE_UNAVAILABLE = 'service_unavailable_modal',
    RESCHEDULE = 'reschedule_modal'
}

export interface SortBy {
    id: number;
    queryTxt: string;
    label: string;
    value: string;
}

export type FilterBy = {
    id: number;
    min: number;
    max: number;
    label: string;
    value: string;
};

export interface SelectedTests {
    [key: string]: { patientCount: number };
}

export interface LabTestContext {
    open: Open;
    testId: string;
    isOpen: boolean;
    loading: boolean;
    moreTests: Test[];
    isDrawerOpen: boolean;
    orderData: any;
    errMsg: string;
    setErrMsg: Dispatch<SetStateAction<string>>;
    setOrderData: Dispatch<SetStateAction<any>>;
    setOpen: Dispatch<SetStateAction<Open>>;
    setTestId: Dispatch<SetStateAction<string>>;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    setMoreTests: Dispatch<SetStateAction<Test[]>>;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedTests: Dispatch<SetStateAction<SelectedTests>>;
    selectedTests: { [key: string]: { patientCount: number } };
}
