import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';
import { useState, useEffect } from 'react';

import { ChangeEvent } from 'react';
import { Open } from '@/services/types/lab-test';
import { FilterBy } from '@/services/types/lab-test';
import { RadioChecked, RadioUnchecked } from '@/lab-test/svg-icon-components';

import styles from './index.module.scss';

const labels: FilterBy[] = [
    { id: 1, label: 'Under ৳500', min: 0, max: 0, value: '500' },
    { id: 2, label: 'Under ৳1000', min: 0, max: 0, value: '1000' },
    { id: 3, label: 'Under ৳2000', min: 0, max: 0, value: '2000' },
    { id: 4, label: 'Under ৳3000', min: 0, max: 0, value: '3000' },
    { id: 5, label: 'Over ৳3000', min: 0, max: 0, value: '3000' }
];

export default function FilterByModal() {
    const { setOpen, setIsDrawerOpen } = useLabTestContext();
    const { setPage, setTests, filterBy, setFilterBy } = useLabTestCategoryContext();

    const [filter, setFilter] = useState<FilterBy>({ id: 0, label: '', value: '', max: 0, min: 0 });

    function handleFilterBy(filter: FilterBy) {
        setFilter((prev) => ({ ...prev, id: filter.id, label: filter.label, value: filter.value }));
    }

    function handleApply() {
        if (filter.id !== 0 || (filter.max !== 0 && filter.max > filter.min) || (filterBy.max !== 0 && filterBy.max > filterBy.min)) {
            setPage((prev) => (prev = 1));
            setTests((prev) => (prev = []));
            setFilterBy((prev) => ({ ...prev, id: filter.id, label: filter.label, max: filter.max, min: filter.min, value: filter.value }));
            setIsDrawerOpen((prev) => (prev = false));
            setOpen((prev) => (prev = Open.NONE));
        }
    }

    function handleClear() {
        if (filterBy.id !== 0) {
            setTests((prev) => (prev = []));
            setPage((prev) => (prev = 1));
            setFilterBy((prev) => ({ ...prev, id: 0, label: '', value: '', max: 0, min: 0 }));
            setOpen((prev) => (prev = Open.NONE));
            setIsDrawerOpen((prev) => (prev = false));
        } else if (filter.id !== 0) {
            setFilter((prev) => ({ ...prev, id: 0, label: '', max: 0, min: 0, value: '' }));
        }
    }

    useEffect(() => {
        if (filterBy.id > 0) {
            setFilter((prev) => ({
                ...prev,
                id: filterBy.id,
                label: filterBy.label,
                max: filterBy.max,
                min: filterBy.min,
                value: filterBy.value
            }));
        }
    }, [filterBy]);

    /* function handleClear() {
        setTests((prev) => (prev = []));
        setMinMax((prev) => ({ ...prev, min: 0, max: 0 }));
        setPage((prev) => (prev = 1));
        setFilterBy((prev) => (prev = { id: 0, label: '', value: '' }));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    }

    function handleApply() {
        setTests((prev) => (prev = []));
        minMax.max > 0 && setFilterBy((prev) => ({ ...prev, min: minMax.min, max: minMax.max }));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    } */

    return (
        <div className={styles['filter-by-modal']}>
            <div className={styles['filter-by-modal-top']}>
                <div>Filter by price</div>
            </div>
            <div className={styles['filter-by-modal-bottom']}>
                {labels.map((label) => (
                    <div key={label.id} className={styles['filter-by-label']} onClick={() => handleFilterBy(label)}>
                        {filter.id === label.id ? <RadioChecked /> : <RadioUnchecked />}
                        <span>{label.label}</span>
                    </div>
                ))}
                <div className={styles['min-max']}>
                    <input
                        type="number"
                        placeholder="৳ Min"
                        defaultValue={filterBy.min > 0 ? filterBy.min : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setFilter((prev) => ({ ...prev, min: parseInt(event.target.value) || 0 }))
                        }
                    />{' '}
                    to
                    <input
                        type="number"
                        placeholder="৳ Max"
                        defaultValue={filterBy.max > 0 ? filterBy.max : ''}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            setFilter((prev) => ({ ...prev, max: parseInt(event.target.value) || 0 }))
                        }
                    />
                </div>
            </div>
            <div className={styles['filter-by-actions']}>
                <button className={styles['clear-btn']} onClick={handleClear}>
                    Clear Filters
                </button>
                <button className={styles['apply-btn']} onClick={handleApply}>
                    Apply
                </button>
            </div>
        </div>
    );
}
