'use client';
import { useState, useEffect } from 'react';
import { cartBseStorageName } from '../apis/config';
import { isValidJSON } from '../utils/clientHelper';

interface Auth {
    cartBseData: any;
}

const useBseCart = (): Auth => {
    const [cartBseData, setCartData] = useState<any>(null);

    const data = typeof window !== 'undefined' ? localStorage.getItem(cartBseStorageName) : null;

    useEffect(() => {
        if (data && isValidJSON(data)) {
            setCartData(JSON.parse(data));
        }
    }, [data]);

    return { cartBseData };
};

export default useBseCart;
