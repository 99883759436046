'use client';

import { useLabTestContext } from '@/lab-test/context/lab-test-context';

import { ReactNode, MouseEvent } from 'react';
import { Close } from '@/lab-test/svg-icon-components';
import { Open } from '@/services/types/lab-test';

import styles from './index.module.scss';

export default function Drawer({ children }: { children: ReactNode }) {
    const { setIsDrawerOpen, setOpen } = useLabTestContext();

    function handleClose() {
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
    }

    return (
        <div className={styles['drawer-wrapper']} onClick={handleClose}>
            <div className={styles['drawer']} onClick={(event: MouseEvent<HTMLDivElement>) => event.stopPropagation()}>
                <div className={styles['icon']} onClick={() => setIsDrawerOpen((prev) => (prev = false))}>
                    <Close />
                </div>
                <div className={styles['drawer-body']}>{children}</div>
            </div>
        </div>
    );
}
