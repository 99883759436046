'use client';
import LoginCompo from '@/app/login/login';
import Modal from '@/components/molecules/modal';
import { useParams, usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { postAutoLogin } from '../apis/api';
import { refPartnerApiStorageName, userStorageName } from '../apis/config';
import { getDeviceType, isValidJSON, updateUserCartRealTime } from '../utils/clientHelper';
import { useBseCartContext } from './user-cart';
import toast from 'react-hot-toast';
import { APP_DOWNLOAD_LINK_ANDROID, APP_DOWNLOAD_LINK_ISO } from '@/services/apis/config';
interface PropsType {
    refPartnerInfo: any;
    setRefPartnerInfo: React.Dispatch<React.SetStateAction<any>>;
    mobileChangAble: boolean;
    setMobileChangAble: React.Dispatch<React.SetStateAction<any>>;
}

const RefPartnerApiContext = createContext<PropsType | undefined>(undefined);

export const RefPartnerApiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const router = useRouter();
    //TODO: OPEN IN APP
    // const userDeviceType = getDeviceType();
    // const handleDownloadBtn = () => {
    //     window.location.href = userDeviceType === 'ANDROID' ? APP_DOWNLOAD_LINK_ANDROID : APP_DOWNLOAD_LINK_ISO;
    // };
    // const getRefPartnerRefFromLocalStorage = () => {
    //     const getData = typeof window !== 'undefined' ? localStorage.getItem(refPartnerApiStorageName) : null;
    //     console.log(getData);
    //     return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
    // };

    const getUserFromLocalStorage = () => {
        const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
        return getData && isValidJSON(getData) ? JSON?.parse(getData) : null;
    };

    const { updateCartDataContext } = useBseCartContext();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [mobileChangAble, setMobileChangAble] = useState(true);
    const [refPartnerInfo, setRefPartnerInfo] = useState<any>({
        refPartner: '',
        mobile: '',
        mobileChangable: '', //yes-no
        showLogin: '', //yes-no
        initialOTPSend: '', //yes-no
        autoLogin: '', //yes-no
        token: '',
        otpSent: '', // unknown
        appNotifyBanner: '' //yes-no
    });

    useEffect(() => {
        if (typeof window !== 'undefined' && !getUserFromLocalStorage()) {
            const searchParams = new URLSearchParams(window.location.search);

            const refPartner = searchParams.get('refPartner');
            const appNotifyBanner = searchParams.get('appNotifyBanner');
            const showLogin = searchParams.get('showLogin');
            const mobileChangable = searchParams.get('mobileChangable');
            const autoLogin = searchParams.get('autoLogin');
            const token = searchParams.get('token');

            localStorage.setItem(
                refPartnerApiStorageName,
                JSON.stringify({
                    refPartner,
                    appNotifyBanner
                })
            );

            if (showLogin === 'yes') {
                if (autoLogin === 'yes' && token) {
                    setShowLoginModal(false);
                } else {
                    setShowLoginModal(true);
                }
            }
            if (mobileChangable === 'no') {
                setMobileChangAble(false);
            }
            setRefPartnerInfo({
                refPartner: refPartner,
                mobile: searchParams.get('mobile')?.replace(/\D/g, '').slice(-11),
                mobileChangable: mobileChangable,
                showLogin: showLogin,
                initialOTPSend: searchParams.get('initialOTPSend'),
                autoLogin: autoLogin,
                token: token,
                otpSent: searchParams.get('otpSent'),
                appNotifyBanner: appNotifyBanner
            });
        }
    }, []);

    useEffect(() => {
        if (refPartnerInfo) {
            const { mobile, autoLogin, token } = refPartnerInfo;
            if (autoLogin && autoLogin === 'yes' && !!mobile && !!token) {
                const handleAutoLogin = async () => {
                    const autoLoginData = await postAutoLogin(mobile, token);
                    if (autoLoginData?.status === 'success') {
                        const { user, b2b_status, authToken } = autoLogin.data;
                        const getUserDataWithPharmacy = (user: any, authToken: any, b2b_status: any) => {
                            return {
                                ...user,
                                authToken,
                                pharmacy_status: b2b_status?.status,
                                pharmacy_name: b2b_status?.pharmacy_name
                            };
                        };

                        const getUserDataWithoutPharmacy = (user: any, authToken: any) => {
                            return {
                                ...user,
                                authToken
                            };
                        };
                        const userData = user.u_pharmacy_id
                            ? getUserDataWithPharmacy(user, authToken, b2b_status)
                            : getUserDataWithoutPharmacy(user, authToken);

                        await new Promise<void>((resolve) => {
                            localStorage.setItem(userStorageName, JSON.stringify(userData));
                            resolve();
                        });
                        setShowLoginModal(false);

                        const getCartData = await updateUserCartRealTime();
                        updateCartDataContext(getCartData);
                        router.refresh();
                    } else {
                        toast.error(autoLoginData?.message ? autoLoginData?.message : 'Something went wrong!', { duration: 4000 });
                    }
                };
                handleAutoLogin();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refPartnerInfo]);

    return (
        <RefPartnerApiContext.Provider value={{ refPartnerInfo, setRefPartnerInfo, mobileChangAble, setMobileChangAble }}>
            {children}
            {/* {getRefPartnerRefFromLocalStorage().appNotifyBanner === 'yes' && (
                <div style={{ position: 'fixed', top: '50%', zIndex: '99999' }}>Tesasdasdasdasdasdsadt</div>
            )} */}
            <Modal isOpen={showLoginModal} setIsOpen={setShowLoginModal}>
                {/* maxHgt={'300px'} contentDesign={{ overflowY: 'auto' }} */}
                <LoginCompo setLoginModal={setShowLoginModal} />
            </Modal>
        </RefPartnerApiContext.Provider>
    );
};

export const useRefPartnerApiContext = () => {
    const context = useContext(RefPartnerApiContext);
    if (!context) {
        throw new Error('useBseCart must be used within a BseCartProvider');
    }
    return context;
};
