'use client';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { cartBseStorageName, isCouponExpireStorageName, lastOrderFromCartStorageName, userStorageName } from '../apis/config';
import { isValidJSON } from '../utils/clientHelper';
import { getAllProductRequestStock } from '../apis/api';
import toast from 'react-hot-toast';
import Loading from '@/components/atoms/loading';

const BseCartContext = createContext<any>(undefined);

export const BseCartProvider = ({ children }: any) => {
    const getUserFromLocalStorage = () => {
        const getData = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;
        return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
    };

    const [cartBseDataContext, setCartData] = useState<any>(null);
    const [allRequestedProducts, setAllRequestedProducts] = useState<any>(null);
    const [lastOrderFromCart, setLastOrderFromCart] = useState<any>(null);
    const [isCouponExpire, setIsCouponExpire] = useState<boolean>(false);

    useEffect(() => {
        const getData = async () => {
            const response = await getAllProductRequestStock();

            if (response?.status === 'success') {
                setAllRequestedProducts(response?.data);
            } else {
                setAllRequestedProducts([]);
                // toast.error(response?.message ? response?.message : 'Something went wrong!');
            }
        };
        if (getUserFromLocalStorage()?.authToken && allRequestedProducts === null) {
            getData();
        }
    }, [allRequestedProducts]);

    useEffect(() => {
        const data = localStorage.getItem(cartBseStorageName);
        const lastOrderFromLocalStorage = localStorage.getItem(lastOrderFromCartStorageName);
        const isCouponExpireStorage = localStorage.getItem(isCouponExpireStorageName);

        if (data && isValidJSON(data)) {
            setCartData(JSON.parse(data));
        }
        if (lastOrderFromLocalStorage && isValidJSON(lastOrderFromLocalStorage)) {
            setLastOrderFromCart(JSON.parse(lastOrderFromLocalStorage));
        }
        if (isCouponExpireStorage && isValidJSON(isCouponExpireStorage)) {
            const lastOrder = JSON.parse(lastOrderFromLocalStorage || 'false');
            if (lastOrder) {
                setIsCouponExpire(Boolean(JSON.parse(isCouponExpireStorage)));
            }
        }
    }, []);

    const updateCartDataContext = (newData: any) => {
        setCartData(newData);
        localStorage.setItem(cartBseStorageName, JSON.stringify(newData));
    };
    //
    const updateLastOrderFromCart = (newData: any) => {
        setLastOrderFromCart(newData);
        localStorage.setItem(lastOrderFromCartStorageName, JSON.stringify(newData));
    };

    const updateCouponExpire = (newData: boolean) => {
        setIsCouponExpire(newData);
        localStorage.setItem(isCouponExpireStorageName, JSON.stringify(newData));
    };

    const updatePrsContext = async () => {
        const response = await getAllProductRequestStock();

        if (response?.status === 'success') {
            setAllRequestedProducts(response?.data);
        } else {
            setAllRequestedProducts([]);
            // toast.error(response?.message ? response?.message : 'Something went wrong!');
        }
    };

    return (
        <BseCartContext.Provider
            value={{
                cartBseDataContext,
                updateCartDataContext,
                allRequestedProducts,
                setAllRequestedProducts,
                updatePrsContext,
                lastOrderFromCart,
                updateLastOrderFromCart,
                isCouponExpire,
                updateCouponExpire
            }}
        >
            {children}
        </BseCartContext.Provider>
    );
};

export const useBseCartContext = () => {
    const context = useContext(BseCartContext);
    if (!context) {
        throw new Error('useBseCart must be used within a BseCartProvider');
    }
    return context;
};

/**
<BseCartProvider>
</BseCartProvider>

    const { cartBseDataContext, updateCartDataContext } = useBseCartContext();

    updateCartData(newCartData);
 */
