'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import { getSortedTests } from '@/services/apis/lab-test/tests';

import { ReactNode, Dispatch, SetStateAction } from 'react';
import { FilterBy, SortBy } from '@/services/types/lab-test';
import { Category } from '@/services/types/lab-test/category';

export interface LabTestCategoryContextTypes {
    tag: string;
    page: number;
    tests: any[];
    loading: boolean;
    sortBy: SortBy;
    testCount: number;
    filterBy: FilterBy;
    setLoading: Dispatch<SetStateAction<boolean>>;
    minMax: { min: number; max: number };
    setMinMax: Dispatch<SetStateAction<{ min: number; max: number }>>;
    setTag: Dispatch<SetStateAction<string>>;
    setPage: Dispatch<SetStateAction<number>>;
    setSortBy: Dispatch<SetStateAction<SortBy>>;
    setTests: Dispatch<SetStateAction<Category[]>>;
    setFilterBy: Dispatch<SetStateAction<FilterBy>>;
    getTestsFromCategory: ({ page, tag, sortBy, filterBy }: { page: number; tag: string; sortBy?: SortBy; filterBy?: FilterBy }) => void;
}

const LabTestCategoryContext = createContext<LabTestCategoryContextTypes>({
    page: 1,
    tag: '',
    tests: [],
    testCount: 0,
    loading: true,
    setLoading: () => ({}),
    setTag: () => ({}),
    setPage: () => ({}),
    setTests: () => ({}),
    setSortBy: () => ({}),
    setMinMax: () => ({}),
    setFilterBy: () => ({}),
    minMax: { min: 0, max: 0 },
    getTestsFromCategory: () => ({}),
    sortBy: { id: 0, label: '', queryTxt: '', value: '' },
    filterBy: { id: 0, label: '', value: '', max: 0, min: 0 }
});

export default function LabTestCategoryContextProvider({ children }: { children: ReactNode }) {
    const [minMax, setMinMax] = useState<{ min: number; max: number }>({ min: 0, max: 0 });
    const [tag, setTag] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [tests, setTests] = useState<Category[]>([]);
    const [testCount, setTestCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<SortBy>({ id: 0, label: '', queryTxt: '', value: '' });
    const [filterBy, setFilterBy] = useState<FilterBy>({ id: 0, label: '', value: '', min: 0, max: 0 });

    async function getTestsFromCategory({ page, tag }: { page: number; tag: string }) {
        const min_max =
            (filterBy.max !== 0 && filterBy.max > filterBy.min && JSON.stringify({ min: filterBy.min, max: filterBy.max })) || null;
        const filterValue = filterBy.id !== 0 && { priceThreshold: `${filterBy.id !== 5 ? 'below' : 'above'}_${filterBy.value}` };
        const sortValue = sortBy.id !== 0 && { [sortBy.queryTxt]: sortBy.value };

        setLoading((prev) => (prev = true));
        const response: any = await getSortedTests({
            page,
            limit: 10,
            tags: `["${tag}"]`,
            priceRange: min_max,
            ...filterValue,
            ...sortValue
        });
        setLoading((prev) => (prev = false));

        if (response.success && page > 1) {
            setTestCount((prev) => (prev = response.count));
            setTests((prev) => [...prev, ...response.data.results]);
        } else if (response.success && page < 2) {
            setTestCount((prev) => (prev = response.count));
            setTests((prev) => (prev = response.data.results));
        }
        setMinMax((prev) => ({ ...prev, min: 0, max: 0 }));
    }

    const contextValue = {
        tests,
        sortBy,
        filterBy,
        testCount,
        tag,
        page,
        minMax,
        loading,
        setLoading,
        setMinMax,
        setTests,
        getTestsFromCategory,
        setTag,
        setSortBy,
        setFilterBy,
        setPage
    };

    return <LabTestCategoryContext.Provider value={contextValue}>{children}</LabTestCategoryContext.Provider>;
}

export const useLabTestCategoryContext = () => useContext(LabTestCategoryContext);
