'use client';
import { useState, useEffect } from 'react';
import { userDefaultLocation } from '../apis/config';
import { isValidJSON } from '../utils/clientHelper';

interface Auth {
    locationData: any;
}

const useDefaultLocation = (): Auth => {
    const [locationData, setLocationData] = useState<any>(null);

    const data = typeof window !== 'undefined' ? localStorage.getItem(userDefaultLocation) : null;

    useEffect(() => {
        if (data && isValidJSON(data)) {
            setLocationData(JSON.parse(data));
        }
    }, [data]);

    return { locationData };
};

export default useDefaultLocation;
