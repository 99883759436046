'use client';

import { createContext, useContext, useState } from 'react';

import { ReactNode } from 'react';
import { BookModal } from '@/lab-test/lazy-load';

import { Test } from '@/services/types/lab-test/tests';
import { LabTestContext as LabTestContexts, Open, SelectedTests, ErrorTag } from '@/services/types/lab-test';

import Drawer from '../molecules/drawer';
import LoginCompo from '@/app/login/login';
import NaModal from '../molecules/na_modal';
import Modal from '@/components/molecules/modal';
import PatientList from '../molecules/patient-list';
import SortByModal from '../molecules/sort-by-modal';
import MoreTestList from '../molecules/more-test-list';
import FilterByModal from '../molecules/filter-by-modal';
import LabTestCategoryContextProvider from './lab-test-category-context';
import LabTestSearchContextProvider from '@/lab-test/context/lab-test-search-context';
import LabCartProvider from './lab-cart-context';

/* const locationInitialData = {
    area: '',
    district: '',
    division: '',
    hubId: '',
    id: '',
    lat: 0,
    long: 0,
    postcode: 0,
    zone: ''
};
const userLocationInitialData = {
    address: '',
    id: '',
    isChecked: false,
    isDefault: false,
    lat: 0,
    location: '',
    locationId: '',
    long: 0,
    mobileNumber: '',
    name: '',
    type: '',
    userId: ''
}; */
/* const cartInitialData = {
    cash: '',
    cashWithoutConveyance: '',
    createdAt: '',
    hardCopyConveyance: '',
    discountAmount: '',
    id: '',
    itemCount: 0,
    items: [],
    labMaterialCharge: '',
    location: locationInitialData,
    priceDiscount: '',
    promoCode: '',
    promoDiscount: '',
    roundingOff: '',
    collectionConveyance: '',
    shouldApplyHardCopyConveyance: false,
    shouldApplyCollectionConveyance: false,
    subtotalAmount: '',
    totalAmount: '',
    totalAmountWithoutConveyance: '',
    updatedAt: '',
    userId: '',
    userLocation: userLocationInitialData
}; */

const LabTestContext = createContext<LabTestContexts>({
    testId: '',
    moreTests: [],
    isOpen: false,
    loading: false,
    open: Open.NONE,
    orderData: null,
    setOrderData: () => {},
    selectedTests: {},
    setOpen: () => {},
    errMsg: '',
    setErrMsg: () => {},
    setIsOpen: () => {},
    setTestId: () => {},
    isDrawerOpen: false,
    setLoading: () => ({}),
    setMoreTests: () => {},
    setIsDrawerOpen: () => {},
    setSelectedTests: () => ({})
});

export default function LabTestContextProvider({ children }: { children: ReactNode }) {
    const [testId, setTestId] = useState<string>('');
    const [open, setOpen] = useState<Open>(Open.NONE);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [moreTests, setMoreTests] = useState<Test[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [selectedTests, setSelectedTests] = useState<SelectedTests>({});
    const [orderData, setOrderData] = useState<any>({ id: 1 });
    const [errMsg, setErrMsg] = useState<string>('');

    const contextValue = {
        open,
        isOpen,
        testId,
        loading,
        setOpen,
        moreTests,
        setIsOpen,
        errMsg,
        setErrMsg,
        orderData,
        setOrderData,
        setTestId,
        setLoading,
        isDrawerOpen,
        setMoreTests,
        selectedTests,
        setIsDrawerOpen,
        setSelectedTests
    };

    return (
        <LabTestContext.Provider value={contextValue}>
            <LabCartProvider>
                <LabTestSearchContextProvider>
                    <LabTestCategoryContextProvider>
                        {children}
                        {open === Open.ITEM_UNAVAILABLE ? (
                            <NaModal errorTag={ErrorTag.ITEM_UNAVAILABLE} message={errMsg} />
                        ) : open === Open.SERVICE_UNAVAILABLE ? (
                            <NaModal errorTag={ErrorTag.SERVICE_UNAVAILABLE} message={errMsg} />
                        ) : open === Open.LOCATION_NOT_SET ? (
                            <NaModal errorTag={ErrorTag.LOCATION_NOT_SET} message={errMsg} />
                        ) : open === Open.ITEM_MAX_REACHED ? (
                            <NaModal errorTag={ErrorTag.ITEM_MAX_REACHED} message={errMsg} />
                        ) : null}
                        {open === Open.BOOK_TEST && (
                            <BookModal>
                                <PatientList />
                            </BookModal>
                        )}
                        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
                            {open === Open.LOGIN && <LoginCompo />}
                            {open === Open.MORE_TEST && <MoreTestList data={moreTests} />}
                        </Modal>
                        {isDrawerOpen && (
                            <Drawer>
                                {open === Open.SORT_BY && <SortByModal />}
                                {open === Open.FILTER_BY && <FilterByModal />}
                            </Drawer>
                        )}
                    </LabTestCategoryContextProvider>
                </LabTestSearchContextProvider>
            </LabCartProvider>
        </LabTestContext.Provider>
    );
}

export const useLabTestContext = () => useContext(LabTestContext);
