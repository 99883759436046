import { useLabTestContext } from '@/lab-test/context/lab-test-context';
import { useLabTestCategoryContext } from '@/lab-test/context/lab-test-category-context';

import { SortBy, Open } from '@/services/types/lab-test';
import { RadioUnchecked, RadioChecked } from '@/lab-test/svg-icon-components';

import styles from './index.module.scss';

const labels: SortBy[] = [
    { id: 1, label: 'Popularity', queryTxt: 'popular', value: 'desc' },
    { id: 2, label: 'Price: low to high', queryTxt: 'price', value: 'asc' },
    { id: 3, label: 'Price: high to low', queryTxt: 'price', value: 'desc' },
    { id: 4, label: 'Discount: high to low', queryTxt: 'discount', value: 'desc' },
    { id: 5, label: 'Discount: low to high', queryTxt: 'discount', value: 'asc' },
    { id: 6, label: 'Alphabatic (A to Z)', queryTxt: 'alphabet', value: 'asc' }
];

export default function SortByModal() {
    const { setOpen, setIsDrawerOpen } = useLabTestContext();
    const { sortBy, setSortBy, setTests, setPage } = useLabTestCategoryContext();

    function handleSortBy(item: SortBy) {
        setTests((prev) => (prev = []));
        setPage((prev) => (prev = 1));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
        setSortBy((prev) => ({ ...prev, id: item.id, label: item.label, queryTxt: item.queryTxt, value: item.value }));
    }

    function handleCealrBtn() {
        setPage((prev) => (prev = 1));
        setTests((prev) => (prev = []));
        setOpen((prev) => (prev = Open.NONE));
        setIsDrawerOpen((prev) => (prev = false));
        setSortBy((prev) => ({ ...prev, id: 0, label: '', value: '' }));
    }

    return (
        <div className={styles['sort-by-modal']}>
            <div className={styles['sort-by-modal-top']}>
                <div>Sort by</div>
                {sortBy.id !== 0 && (
                    <div onClick={handleCealrBtn} className={styles['clear-btn']}>
                        Clear sort by
                    </div>
                )}
            </div>
            <div className={styles['sort-by-modal-bottom']}>
                {labels.map((label) => (
                    <div key={label.id} className={styles['sort-by-label']} onClick={() => handleSortBy(label)}>
                        {label.id === sortBy.id ? <RadioChecked /> : <RadioUnchecked />}
                        <span>{label.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
