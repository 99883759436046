'use client';

import { createContext, useContext, useState, useEffect } from 'react';
import { getTestsCart, addTestToCart, deleteTestCartItem } from '@/services/apis/lab-test/cart';
import { generateLabCartContext, generateLabCart, generateLabTest } from '@/services/utils/generators';
import { useLabTestPixelEvent } from '@/services/event-log-tracking/useLabTestPixelEvent';
import { useLabTestContext } from './lab-test-context';

import { Open } from '@/services/types/lab-test';
import { CustomToastMessageCart } from '@/components/atoms/custom-taost-message';

import type { ReactNode } from 'react';
import type { Cart, TestsInCart } from '@/services/types/lab-test/cart';

import toast from 'react-hot-toast';
import useAuth from '@/services/hooks/use-auth';

const LabCartContext = createContext(generateLabCartContext());

export default function LabCartProvider({ children }: { children: ReactNode }) {
    const { user } = useAuth();
    const { testId, setOpen } = useLabTestContext();
    const { LabTestAddToCartEvent } = useLabTestPixelEvent();

    const [labCart, setLabCart] = useState(generateLabCart());
    const [testsInCart, setTestsInCart] = useState<TestsInCart>({});
    const [loading, setLoading] = useState<boolean>(false);
    const [test, setTest] = useState(generateLabTest());

    async function getLabCart(): Promise<void> {
        const response: Cart = await getTestsCart();

        if (response?.success) {
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            response?.data?.items?.map((item) => {
                setTestsInCart((prev) => ({ ...prev, [item?.item?.id]: { patientCount: item?.patientCount } }));
            });
        }
    }

    async function addToCart(patientCount: number): Promise<void> {
        setLoading((prev) => (prev = true));
        const response = await addTestToCart(testId, patientCount);
        setLoading((prev) => (prev = false));

        if (response?.success) {
            LabTestAddToCartEvent({ testData: test, patientCount });
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            setTestsInCart((prev) => ({ ...prev, [testId]: { patientCount } }));
            setOpen((prev) => (prev = Open.NONE));
            setTest((prev) => ({ ...prev, ...generateLabTest() }));
            toast(() => <CustomToastMessageCart check close={false} label="Cart" type="add" link="/shopping-cart/lab-test" />);
        } else if (!response?.success && response?.data?.errorTag) {
            alert('something');
        }
    }

    async function removeFromCart(testId: string): Promise<void> {
        setLoading((prev) => (prev = true));
        const response = await deleteTestCartItem(testId);
        setLoading((prev) => (prev = false));

        if (response?.success) {
            setLabCart((prev) => ({ ...prev, ...response?.data }));
            setTestsInCart((prev) => {
                delete prev[testId];
                return prev;
            });
            setOpen((prev) => (prev = Open.NONE));
            toast(() => <CustomToastMessageCart close noLink check={false} label="Cart" type="remove" />);
        }
    }

    const value = {
        getLabCart,
        testsInCart,
        setTestsInCart,
        addToCart,
        removeFromCart,
        loading,
        setLoading,
        labCart,
        setLabCart,
        test,
        setTest
    };

    useEffect(() => {
        user && getLabCart();
    }, [user]);

    return <LabCartContext.Provider value={value}>{children}</LabCartContext.Provider>;
}

export const useLabCartContext = () => useContext(LabCartContext);
