import querystring from 'qs';
import { request } from '../arogga-api';
import { Status } from '@/services/types/lab-test';

export const getFrequentLabItems = (q: { page?: number; limit?: number }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/frequent?${qs}`
    });
};

export const getLabTestsCategories = (q: { page?: number; limit?: number; sectionTag: string; status?: Status; name?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/category?${qs}`
    });
};

export const getPopularLabItems = (q: { page?: number; limit?: number; status?: Status }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/popular?${qs}`
    });
};

export const getLabTests = (q: { page?: number; limit: number; tag?: string; status?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items?${qs}`
    });
};

export const getPackageDetails = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/${id}`
    });
};

export const getPackageTests = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/package/${id}/tests`
    });
};

export const getRiskAssessment = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/details/?itemId=${id}`
    });
};

export const getFaq = (id: string, q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/faq/?itemId=${id}`
    });
};

export const getSteps = (q = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-steps`
    });
};

export const getRecommendedPackages = (q: any = '') => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `misc/api/v1/lab-items/recommendation?${qs}`
    });
};

export const getSearchedLabTests = (q: { page?: number; limit?: number; query?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v1/search?${qs}`
    });
};

export const getTrendingTests = (q: { page?: number; limit?: number; query?: string }) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v1/search/trending?${qs}`
    });
};

export const getSortedTests = (q: any) => {
    const qs = querystring.stringify(q);
    return request({
        method: 'GET',
        includeGeneral: false,
        endpoint: `lab-search/api/v1/search/category?${qs}`
    });
};
